<template>
  <v-app :class="url" v-if="url">
    <v-app-bar app v-if="user">
      <v-toolbar-title class>
        <router-link to="/" v-if="!isFuelCell">
          <v-img v-if="url === 'tl'" :src="require('@/assets/templink-logo.svg')" width="170" />
          <v-img v-else-if="url === 'fcmi'" :src="require('@/assets/logo.png')" width="100" />
          <v-img v-else-if="url === 'primetech'" :src="require('@/assets/primetech-logo.svg')" width="170" />
        </router-link>
        <!-- <router-link to="/fuelcell/1" v-else>
          <v-img :src="require('@/assets/logo.png')" width="100" />
        </router-link> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isMDX || isTDX || isKDX || isReadOnly">
        <v-btn text to="/">Dashboard</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="isCustomer">
        <v-btn text to="/">Dashboard</v-btn>
        <!-- <v-btn text to="/profile">Profile</v-btn> -->
        <!-- <v-btn text v-if="!previousCustomer" to="/add">Add Sensor</v-btn> -->
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="isFuelCell">
        <v-btn text to="/fuelcell/1">Dashboard</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="!isUser">
        <v-btn text to="/">Dashboard</v-btn>
        <!-- <v-btn text to="/fuelcell">Fuel Cells</v-btn> -->
        <v-btn text v-if="isSuper" to="/stock">Stock</v-btn>
        <v-btn text v-if="isSuper" to="/users">Users</v-btn>
        <v-btn text v-if="isSuper" to="/gateways">Gateways</v-btn>
        <v-btn text v-if="isSuper" to="/stores">Stores</v-btn>
        <v-btn text v-if="isSuper" to="/locations">Locations</v-btn>
        <!-- <v-btn v-if="isSuper || isDistributor" text to="/stores">Stores</v-btn> -->
        <!-- <v-btn v-if="isSuper" text to="/locations">Locations</v-btn> -->
        <!-- <v-btn text to="/add">Add Sensor</v-btn> -->
        <!-- <v-btn text to="/testing">Testing</v-btn> -->
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn text to="/">Dashboard</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-app-bar app elevation="1" v-else>
      <v-toolbar-title class="headline text-uppercase">
        <router-link to="/" v-if="!isFuelCell">
          <v-img v-if="url === 'tl'" :src="require('@/assets/templink-logo.svg')" width="170" />
          <v-img v-else-if="url === 'fcmi'" :src="require('@/assets/logo.png')" width="100" />
          <v-img v-else-if="url === 'primetech'" :src="require('@/assets/primetech-logo.svg')" width="170" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$route.name === 'dashboard'">
        <v-btn text to="/manage">Add Sensor</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-5">
        <router-view :key="$route.fullPath" class="sensors" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "App",
  data: () => ({
    drawer: false,
    item: null
  }),
  async created() {
    // this.$store.dispatch("sensor/allSensors");
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "isAdmin",
      "isUser",
      "isCustomer",
      "isLocation",
      "isMDX",
      "isTDX",
      "isKDX",
      "isFuelCell",
      "userDistributorName",
    ]),
    isReadOnly() {
      if (this.isLocation || this.isAdmin) return true
      else return false
    },
    previousCustomer() {
      return this.checkEmail(this.user.attributes.email);
    },
    // site() {
    //   return window.location.hostname.includes('localhost') ? 'tl' : window.location.hostname.includes('fcmi') ? 'fcmi' : null
    // }
  },
  methods: {
    checkEmail(email) {
      if (
        email === "dairyqueen@mail.com" ||
        email === "cdshaw1958@gmail.com" ||
        email === "agordonfsf@rrv.net"
      ) {
        return true;
      } else return false;
    },
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "Poppins", sans-serif;
  .v-toolbar__items {
    .v-btn__content {
      text-transform: capitalize;
      font-weight: bold;
    }
  }
}
.v-small-dialog__activator__content{
    display: block !important;
}
table .v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}
.tl {
  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #000;
    height: 83px !important;
    border-bottom: 8px solid #0095da;
  }
  .v-toolbar__items {
    .v-btn {
      color: #fff;
    }
  }
  .v-main {
    padding: 83px 0px 0px !important;
  }
  .v-toolbar__content{
    height: 75px !important;
  }
}
.primetech {
  font-family: "Titillium Web", sans-serif !important;
  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #000;
    height: 83px !important;
    border-bottom: 8px solid #EA0001;
  }
  .v-toolbar__items {
    .v-btn {
      color: #CEB589;
      font-style: bold;
      font-size: 15px;
    }
  }
  .v-main {
    padding: 83px 0px 0px !important;
  }
  .v-toolbar__content{
    height: 75px !important;
  }
}
</style>
